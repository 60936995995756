<template>
    <table class="col-12">
        <thead>
            <tr>
                <th class="width-10">#</th>
                <th class="width-25">Просмотр</th>
                <th class="width-10">Организация</th>
                <th class="width-10">Автомобиль</th>
                <th class="width-25">Дата создания</th>
                <th class="width-25">Статус</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ProtectionReportTableElement 
            v-for="report in results" 
            :key="report.id"
            :id = "report.id"
            :company_name="report.company_name"
            :car_number="report.car_number"
            :finish="report.finish"
            :created_at="report.created_at"
            />
        </tbody>
    </table>
</template>
<script>
    import ProtectionReportTableElement from "@/components/Reports/LaborProtection/Elements/ProtectionView/ProtectionReportTableElement.vue";

    export default {
        components: { ProtectionReportTableElement },
        props: ['results'],
    }
</script>