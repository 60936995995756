<template>
    <tr>
        <td><router-link :to="{path: '/reports/protection/' + protectionId + '/report/' + id}">{{id}}</router-link></td>
        <td><router-link :to="{path: '/reports/protection/' + protectionId + '/report/' + id}">Просмотреть отчёт</router-link></td>
        <td>{{ company_name }}</td>
        <td>{{car_number}}</td>
        <td>{{ format_date(created_at) }}</td>
        <td><span class="icon-Check" :class="{ green: finish }"></span>{{ checklistStatus(finish) }}</td>
        <td class="right"><router-link :to="{path: '/protection/' + protectionId + '/report/' + id}"><span class="icon-more-vertical"></span></router-link></td>
    </tr>
</template>

<script>
    import moment from 'moment'
    
    export default {
        data() {
            return {
                protectionId: this.$route.params.id
            }
        },
        props: ['id', 'company_name', 'car_number', 'finish', 'created_at'],
        methods: { 
            format_date(value){
                if (value) {
                    return moment(String(value)).format('DD.MM.YYYY')
                }
            },
            checklistStatus(finish) {
                if (finish == false) {
                    return 'В работе'
                } 
                else {
                    return "Сдан"
                }
            }
        },
    }
</script>